<template>
	<article class="view-week-questions">
		<div class="site-wrapper rythm-v-l">
			<header class="page-header">
				<h1>{{ $t("Questions de la semaine") }}</h1>
			</header>

			<section class="section--question rythm-v">
				<div class="toolbar"
						 data-toolbar="week-questions">
					<label for="select-week">{{ $t("Période :") }}</label>

					<select name="select-week"
									class="vgt-select"
									v-model="selectedPeriod"
									@change="selectPeriod">
						<option v-for="period in assignments"
										:value="period">{{ formatAssignmentPeriod(period) }}</option>
					</select>
				</div>

				<section class="section--week-questions-details">
					<div class="card shadow empty-state flex-col flex-center flex-item--100"
							 v-show="!weekQuestions.length">
						<span>{{ $t("Aucune question pour le moment") }}</span>
					</div>

					<div class="card shadow question-table-wrapper flex-item--100"
							 v-show="weekQuestions.length">
						<vue-good-table v-if="weekQuestions.length"
														ref="weekQuestionsTable"
														:columns="columnsTitles"
														:rows="weekQuestions"
														styleClass="vgt-table striped"
														mode="remote"
														:paginate="true"
														:pagination-options="dataPaginationOptions"
														:totalRows="totalRecords"
														@on-page-change="onPageChange"
														@on-per-page-change="onPerPageChange">
							<template slot="table-column"
												slot-scope="props">
								<span>{{ props.column.label }}</span>
							</template>

							<template slot="table-row"
												slot-scope="props">
								<div class="question-title-wrap flex-row flex-center-v"
										 v-if="props.column.field == 'title'">
									<details class="week-question-excerpt"
													 :ref="`excerpt${props.row.id}`"
													 :data-excerpt="props.row.id"
													 @click="excerptToggle">
										<summary class="details-title">
											<span>{{ props.formattedRow[props.column.field] }}</span>
										</summary>
										<div class="details-body">
											<div class="week-question-details">
												<div class="message"
														 v-html="props.row.details"></div>
												<footer>
													<div class="user-card">
														<header>{{ props.row.user.name }}</header>
													</div>
													<div class="links">
														<router-link class="flex-item--grow"
																				 :to="`/question/${props.row.id}`">{{ $t("Afficher la question") }}</router-link>
														<button type="button"
																		class="button--link"
																		@click.prevent="toggleMailtoModal(props.row)">{{ $t("Demande d’échange") }}</button>
													</div>
												</footer>
											</div>
											<ul>
												<li v-for="answer in props.row.answers"
														class="week-question-answer">
													<div class="message"
															 v-html="answer.details"></div>
													<div class="user-card">
														<header>{{ answer.user }}</header>
														<div>- {{ answer.date_modified }}</div>
													</div>
												</li>
											</ul>
										</div>
									</details>
								</div>

								<!-- Any other column (number, date, …) -->
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>

							<div slot="emptystate">
								<p class="text-center">
									{{ $t("Aucune question ne correspond aux filtres sélectionnés.") }}
								</p>
							</div>
						</vue-good-table>
					</div>
				</section>
			</section>
		</div>
		<mailto-modal :open="modalMailtoVisible"
									v-if="currentQuestion"
									:key="currentQuestion.id"
									:question="currentQuestion"
									@modalClose="modalClose" />
	</article>
</template>

<style lang="scss">
@import "@/scss/week-questions.scss";
</style>

<script>
import { HTTP } from "../http-common.js";
import { VueGoodTable } from "vue-good-table";
import MailtoModal from "@/components/MailtoModal.vue";
import { mapGetters } from "vuex";

// Component
export default {
	name: "WeekQuestions",
	components: {
		VueGoodTable,
		MailtoModal,
	},

	data: function () {
		return {
			API_URL: HTTP.defaults.baseURL,
			userToken: this.$store.state.userToken,
			assignments: [],
			weekQuestions: [],
			currentQuestion: null,
			selectedPeriod: null,

			// Modals status
			modalMailtoVisible: false,

			// Table server mode
			totalRecords: 0,
			serverParams: {
				columnFilters: {},
				sort: [
					{
						field: "",
						type: "",
					},
				],
				page: 1,
				perPage: 20,
			},

			// Table pagination
			dataPaginationOptions: {
				enabled: true,
				nextLabel: this.$t("suivant"),
				prevLabel: this.$t("précédent"),
				rowsPerPageLabel: this.$t("lignes par page"),
				ofLabel: this.$t("sur"),
				perPage: 20,
				pageLabel: this.$t("page"), // for 'pages' mode
				allLabel: this.$t("Toutes"),
			},
		};
	},

	computed: {
		// Store getters bindings
		...mapGetters({
			lang: "currentLang",
		}),


		paginationOptions() {
			return {
				enabled: true,
				nextLabel: this.$t("suivant"),
				prevLabel: this.$t("précédent"),
				rowsPerPageLabel: this.$t("lignes par page"),
				ofLabel: this.$t("sur"),
				perPage: 20,
				pageLabel: this.$t("page"), // for 'pages' mode
				allLabel: this.$t("Toutes"),
			}
		},

		columnsTitles() {
			return [
				{
					label: this.$t("Titre de la question"),
					labelFR: "Titre de la question",
					field: "title",
					sortable: false,
					tdClass: "",
				},
				{
					label: this.$t("Date"),
					labelFR: "Date",
					field: "date_created",
					type: "date",
					dateInputFormat: "yyyy-MM-dd H:mm:s",
					dateOutputFormat: "dd/MM/yyyy H:mm",
					sortable: false,
					tdClass: "justify-top",
					width: "200px",
				},
				{
					label: this.$t("Affectation"),
					labelFR: "Affectation",
					field: "expert",
					sortable: false,
					tdClass: " justify-top",
					width: "400px",
				},
			];
		},

		// Current user data
		user: function () {
			return this.$store.state.userData;
		},

		// Is the user an admin? (role 4)
		user_is_admin: function () {
			return this.user.roles.indexOf(4) !== -1;
		},

		// Is the user a coordinator? (role 3)
		user_is_coordinator: function () {
			return this.user.roles.indexOf(3) !== -1;
		},

		// Is the user an expert? (role 2)
		user_is_expert: function () {
			return this.user.roles.indexOf(2) !== -1;
		},

		// Is the user an expert extended? (role 5)
		user_is_expert_ext: function () {
			return this.user.roles.indexOf(5) !== -1;
		},

		// Is a regular user?
		user_is_regular: function () {
			let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
			return r < 2;
		},

	},

	methods: {

		// Set current period (from assignments)
		setCurrentPeriod() {
			if (!this.assignments) return null;

			// find the current period from current date…
			let currentAssignmentIndex = this.assignments.findIndex($ => {
				const dateStart = new Date($?.date_start);
				const dateEnd = new Date($?.date_end);
				const today = new Date();
				return today >= dateStart && today <= dateEnd;
			});

			// then get the previous period
			const previousPeriod = !currentAssignmentIndex || currentAssignmentIndex - 1 < 0 ? this.assignments[0] : this.assignments[currentAssignmentIndex - 1];

			this.selectedPeriod = previousPeriod;
		},

		// Load assignments (current year period) to build the weeks list
		async getAssignments() {
			const year = new Date().getFullYear();
			let _assignments = await this.$store.dispatch("GET_ASSIGNMENTS", { periodStart: `${year}-01-01`, periodEnd: `${year}-12-31` }).then(results => results);
			if (!_assignments) return [];
			// get rid of the key (convert object to array of objects)
			_assignments = Object.values(_assignments);
			this.assignments = _assignments;
			return this.assignments;
		},


		dateStringToYMD(dateString) {
			const d = new Date(dateString);
			return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)
				.toISOString()
				.split("T")[0];
		},

		// Récupération des questions par semaine
		getWeekQuestions: function () {

			const dateStartString = this?.selectedPeriod?.date_start;
			const dateEndString = this?.selectedPeriod?.date_end;
			if (!dateStartString || !dateEndString) return false;

			// date to yyyy-mm-dd
			const periodStart = this.dateStringToYMD(dateStartString);
			const periodEnd = this.dateStringToYMD(dateEndString);

			const page = this.serverParams.page;
			const perPage = this.serverParams.perPage;

			this.$store
				.dispatch("GET_WEEK_QUESTIONS", { periodStart, periodEnd, page, perPage })
				.then((payload) => {
					if (!payload || !Object.entries(payload).length) {
						payload.weekQuestions = [];
						payload.totalRecords = 0;
					}

					this.weekQuestions = payload.weekQuestions;
					this.totalRecords = payload.totalRecords ? payload.totalRecords : 0;
				})
				.catch((error) => {
					console.error("getWeekQuestions error", error);
				});
		},

		// on modifie l'url lorsque l'on selectionne une semaine
		selectPeriod: function (event) {
			if (!this.selectedPeriod) return false;

			// date to yyyy-mm-dd
			const periodStart = this.dateStringToYMD(this.selectedPeriod.date_start);
			const periodEnd = this.dateStringToYMD(this.selectedPeriod.date_end);

			this.$router.push(`/week-questions/${periodStart}/${periodEnd}`);
		},


		excerptToggle(e) {
			let details = e.target.closest("details");
			let allDetails = this.$refs.weekQuestionsTable.$el.querySelectorAll("[data-excerpt]");
			allDetails.forEach((elt) => {
				if (elt.getAttribute("data-excerpt") !== details.getAttribute("data-excerpt")) elt.removeAttribute("open");
			});
		},

		// Format week date start/end for <select>
		formatAssignmentPeriod(assignment) {
			if (!assignment) return '?';
			const options = { year: "numeric", month: "numeric", day: "numeric" };
			const dateStart = new Date(assignment.date_start);
			const dateEnd = new Date(assignment.date_end);
			const dateStartString = dateStart.toLocaleDateString(this.lang, options);
			const dateEndString = dateEnd.toLocaleDateString(this.lang, options);
			return `${this.$t("Semaine")} ${this.$t("du ")} ${dateStartString} ${this.$t(" au ")} ${dateEndString}`;
		},

		// toggle mailto modal
		toggleMailtoModal: function (question) {
			this.currentQuestion = question;

			// Close modal
			if (this.modalMailtoVisible === true) {
				this.modalMailtoVisible = false;
				return;
			} else {
				this.modalMailtoVisible = true;
			}
		},

		// close modal
		modalClose: function (event) {
			this.modalMailtoVisible = false;
		},

		// Changement de page de la table
		onPageChange(params) {
			// save pagination preferences
			this.$store.dispatch("SAVE_WEEK_QUESTIONS_CURRENT_PAGE", params.currentPage);

			// update params and reload questions
			this.updateParams({ page: params.currentPage });

			this.getWeekQuestions(); // vérifier si nécessaire
		},

		// Changement du nombre de lignes par page de la table
		onPerPageChange(params) {
			// save pagination preferences
			this.$store.dispatch("SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE", params.currentPerPage);

			// update params and reload questions
			this.updateParams({ perPage: params.currentPerPage });

			this.getWeekQuestions(); // vérifier si nécessaire
		},

		updateParams(newProps) {
			this.serverParams = Object.assign({}, this.serverParams, newProps);
		},

		/**
		 * Update table labels
		 * Translations are not reactive to language change.
		 * Strings need to be translated from the french texts.
		 *
		 * @return void
		 */
		updateLabels() {
			// Columns labels
			this.columnsTitles.map((c) => {
				if (c?.labelFR) c.label = this.$t(c.labelFR);
				else c.label = this.$t(c.label);
			});
		},

		updatePaginationOptions() {
			// Load pagination preferences
			this.dataPaginationOptions = { ...this.paginationOptions, ...this.$store.state.currentWeekQuestionsPaginationOptions };
		}
	},

	async mounted() {
		// Not for regular users
		if (this.user_is_regular) {
			this.$router.push("/dashboard");
		}

		// Get assignments
		await this.getAssignments();

		// Pagination
		this.updatePaginationOptions();

		// Display last page
		if (this.dataPaginationOptions.savedCurrentPage) {
			this.updateParams({ page: this.dataPaginationOptions.savedCurrentPage });
			delete this.dataPaginationOptions.savedCurrentPage;
		}

		// set period from route
		const periodStart = this.$route.params?.periodStart;
		const periodEnd = this.$route.params?.periodEnd;

		if (periodStart && periodEnd) {
			const periodStartDate = new Date(`${periodStart} 17:00:00`);
			const periodEndDate = new Date(`${periodEnd} 16:59:59`);

			const currentAssignmentIndex = this.assignments.findIndex($ => {
				const dateStart = new Date($?.date_start);
				const dateEnd = new Date($?.date_end);
				return periodStartDate >= dateStart && periodEndDate <= dateEnd;
			});

			this.selectedPeriod = this.assignments[currentAssignmentIndex];
		}
		else {
			// set period to the previous period from current date
			this.setCurrentPeriod();
		}

		// Load week questions
		this.getWeekQuestions();
	},

	watch: {
		$route(to, from) {
			// react to route changes
			this.getWeekQuestions();
		},

		// Update table on lang change
		lang: {
			immediate: false,
			handler(after, before) {
				if (after === before) return;
				this.updateLabels();
				this.updatePaginationOptions();
			},
		},
	},
};
</script>
